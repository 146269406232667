import { type ClassValue } from 'clsx';
import { ALERT_TYPES, Alert as AlertType } from 'constants/alerts';
import { memo, ReactNode } from 'react';
import isEqual from 'react-fast-compare';
import { useIntl } from 'react-intl';
import { useCustomerFeedbackActions, useCustomerFeedbackSubmitted } from 'store/customerFeedback';
import { useError } from 'store/error';
import { Icon, Text } from 'ui';
import { cn } from 'utils/cn';

const colors: Record<AlertType, ClassValue> = {
  danger: 'border-danger bg-danger-20',
  success: 'border-success bg-success-20',
  warning: 'border-warning bg-warning-20',
};

export interface AlertProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  showArrow?: boolean;
  showFeedbackLink?: boolean;
  type?: AlertType;
}

const Alert = ({
  children,
  className,
  onClick,
  showArrow,
  showFeedbackLink,
  type = ALERT_TYPES.WARNING,
}: AlertProps) => {
  const { formatMessage } = useIntl();

  const error = useError();
  const customerFeedbackSubmitted = useCustomerFeedbackSubmitted();
  const { setCustomerFeedbackOpen } = useCustomerFeedbackActions();

  const toggleFeedbackModal = () => {
    setCustomerFeedbackOpen(true);
  };

  if (error && customerFeedbackSubmitted && showFeedbackLink) {
    return <Text className={className}>{formatMessage({ id: 'general_customer_feedback_submitted' })}</Text>;
  }

  const alertClickable = (!!showArrow && !!onClick) || (!!showFeedbackLink && !!error);

  return (
    <>
      <div
        className={cn(
          'mb-4 flex items-center justify-between rounded-alert border-l-8 p-4',
          onClick || showFeedbackLink ? 'cursor-pointer' : '',
          colors[type],
          className,
        )}
        onClick={showFeedbackLink ? toggleFeedbackModal : onClick}
        role={alertClickable ? 'button' : undefined}
        tabIndex={alertClickable ? 0 : undefined}
      >
        <div className="flex w-full flex-col">{children}</div>
        {alertClickable && (
          <div className="flex items-center justify-center">
            <Icon icon={{ name: 'angle-right', styling: 'fal' }} className="ml-3 size-6 text-black" />
          </div>
        )}
      </div>
    </>
  );
};

export default memo(Alert, isEqual);
